/*table.dataTable.checkboxes-select tbody tr,*/
table.dataTable thead .checkboxes-select-all {
    cursor: pointer;
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
    margin-left: 0.5em;
}

@media screen and (max-width: 640px) {
    div.dataTables_wrapper span.select-info,
    div.dataTables_wrapper span.select-item {
        display: block;
        margin-left: 0;
    }
}
