/**
 * Theme for jQRangeSlider
 * Inspired by http://cssdeck.com/item/381/itunes-10-storage-bar
 *        and http://cssdeck.com/item/276/pure-css-arrow-with-border-tooltip
 */

.ui-rangeSlider {
    height: 30px;
    padding-top: 40px;
}

.ui-rangeSlider,
.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.ui-rangeSlider-withArrows .ui-rangeSlider-container {
    margin: 0 15px;
}

.ui-rangeSlider-withArrows .ui-rangeSlider-container,
.ui-rangeSlider-noArrow .ui-rangeSlider-container,
.ui-rangeSlider-arrow {
    -webkit-box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.5);
    box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.5);
}

.ui-rangeSlider-disabled.ui-rangeSlider-withArrows .ui-rangeSlider-container,
.ui-rangeSlider-disabled.ui-rangeSlider-noArrow .ui-rangeSlider-container,
.ui-rangeSlider-disabled .ui-rangeSlider-arrow {
    -webkit-box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.3);
    box-shadow: inset 0 4px 6px -2px RGBA(0, 0, 0, 0.3);
}

.ui-rangeSlider-noArrow .ui-rangeSlider-container {
    border-right: solid 1px #515862;
    border-left: solid 1px #515862;
    border-radius: 4px;
}

.ui-rangeSlider-disabled.ui-rangeSlider-noArrow .ui-rangeSlider-container {
    border-color: #8490a3;
}

.ui-rangeSlider-container,
.ui-rangeSlider-arrow {
    height: 30px;
    border-top: solid 1px #232a32;
    border-bottom: solid 1px #6a7179;
}

.ui-rangeSlider-disabled .ui-rangeSlider-container,
.ui-rangeSlider-disabled .ui-rangeSlider-arrow {
    border-top-color: #49576b;
    border-bottom-color: #9ca7b3;
}

.ui-rangeSlider-container,
.ui-rangeSlider-arrow,
.ui-rangeSlider-label {
    background: #67707f;
    background: -moz-linear-gradient(top, #67707f 0%, #888da0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #67707f), color-stop(100%, #888da0));
}

.ui-rangeSlider-disabled .ui-rangeSlider-container,
.ui-rangeSlider-disabled .ui-rangeSlider-arrow,
.ui-rangeSlider-disabled .ui-rangeSlider-label {
    background: #95a4bd;
    background: -moz-linear-gradient(top, #95a4bd 0%, #b2bbd8 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #95a4bd), color-stop(100%, #b2bbd8));
}

.ui-rangeSlider-arrow {
    width: 14px;
    cursor: pointer;
}

.ui-rangeSlider-leftArrow {
    border-left: solid 1px #515862;
    border-radius: 4px 0 0 4px;
}

.ui-rangeSlider-disabled .ui-rangeSlider-leftArrow {
    border-left-color: #8792a2;
}

.ui-rangeSlider-rightArrow {
    border-right: solid 1px #515862;
    border-radius: 0 4px 4px 0;
}

.ui-rangeSlider-disabled .ui-rangeSlider-rightArrow {
    border-right-color: #8792a2;
}

.ui-rangeSlider-arrow-inner {
    position: absolute;
    top: 50%;
    width: 0;
    height: 0;
    margin-top: -10px;
    border: 10px solid transparent;
}

.ui-rangeSlider-leftArrow .ui-rangeSlider-arrow-inner {
    left: 0;
    margin-left: -8px;
    border-right: 10px solid #a4a8b7;
}

.ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner {
    border-right: 10px solid #b3b6c2;
}

.ui-rangeSlider-disabled .ui-rangeSlider-leftArrow .ui-rangeSlider-arrow-inner,
.ui-rangeSlider-disabled .ui-rangeSlider-leftArrow:hover .ui-rangeSlider-arrow-inner {
    border-right-color: #bbc0cf;
}

.ui-rangeSlider-rightArrow .ui-rangeSlider-arrow-inner {
    right: 0;
    margin-right: -8px;
    border-left: 10px solid #a4a8b7;
}

.ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner {
    border-left: 10px solid #b3b6c2;
}

.ui-rangeSlider-disabled .ui-rangeSlider-rightArrow .ui-rangeSlider-arrow-inner,
.ui-rangeSlider-disabled .ui-rangeSlider-rightArrow:hover .ui-rangeSlider-arrow-inner {
    border-left-color: #bbc0cf;
}

.ui-rangeSlider-innerBar {
    left: -10px;
    overflow: hidden;
    width: 110%;
    height: 100%;
}

.ui-rangeSlider-bar {
    height: 29px;
    margin: 1px 0;
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
    cursor: -moz-grab;
    border-radius: 4px;
    background: #68a1d6;
    -webkit-box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.5);
    box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.5);
}

.ui-rangeSlider-disabled .ui-rangeSlider-bar {
    background: #93aeca;
    -webkit-box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.3);
    box-shadow: inset 0 2px 6px RGBA(0, 0, 0, 0.3);
}

.ui-rangeSlider-handle {
    width: 10px;
    height: 30px;
    cursor: col-resize;
    background: transparent;
}

.ui-rangeSlider-label {
    bottom: 40px;
    padding: 5px 10px;
    cursor: col-resize;
    color: white;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 0 #c2c5d6;
    box-shadow: 0 1px 0 #c2c5d6;
    font-size: 15px;
}

.ui-rangeSlider-label-inner {
    position: absolute;
    z-index: 99;
    top: 100%;
    left: 50%;
    display: block;
    margin-left: -10px;
    border-top: 10px solid #888da0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
}

.ui-rangeSlider-disabled .ui-rangeSlider-label-inner {
    border-top-color: #b2bbd8;
}

.ui-editRangeSlider-inputValue {
    width: 2em;
    text-align: center;
    font-size: 15px;
}

.ui-rangeSlider .ui-ruler-scale {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ui-rangeSlider .ui-ruler-tick {
    float: left;
}

.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-inner {
    position: relative;
    height: 29px;
    margin-top: 1px;
    padding-left: 2px;
    color: white;
    border-left: 1px solid white;
}

.ui-rangeSlider .ui-ruler-scale0 .ui-ruler-tick-label {
    position: absolute;
    bottom: 6px;
}

.ui-rangeSlider .ui-ruler-scale1 .ui-ruler-tick-inner {
    height: 5px;
    margin-top: 25px;
    border-left: 1px solid white;
}
