@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Regular.16ce12f328ace29704c78b18d1458314.woff2) format('woff2'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Regular.666d1cd0ab8ffc6e7e095cb4aad0ab75.woff) format('woff'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Regular.a124597bae89d6075e336c1d8db22f65.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Round'),
       local('MaterialIcons-Round'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Round.8f2da8bccf7d25bd1f8111a5e2397eff.woff2) format('woff2'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Round.8bae51002fdead94b212865ff3df48ca.woff) format('woff'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Round.4b81b9fda9de680e3e5d4522459ca941.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Outlined'),
       local('MaterialIcons-Outlined'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Outlined.30729d7d5c2a65934a0dd80253482ff1.woff2) format('woff2'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Outlined.715fb98348c5b1676f5a2dc60e0e09b5.woff) format('woff'),
       url(../fonts/limitless/css/icons/material-icons/fonts/MaterialIcons-Outlined.3bbe6d85f5bd8d1e28d3ea06ebbf8a9c.ttf) format('truetype');
}

[class^="material-icons"], [class*="material-icons"] {
    font-family: 'Material Icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	min-width: 1em;
	display: inline-block;
	text-align: center;
	font-size: 21px;
	vertical-align: middle;
	position: relative;
	top: -1px;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.material-icons--maximize:before {content: "maximize";}
.material-icons--home:before {content: "home";}
.material-icons--view_carousel:before {content: "view_carousel";}
.material-icons--blur_linear:before {content: "blur_linear";}
.material-icons--archive:before {content: "archive";}
.material-icons--straighten:before {content: "straighten";}
.material-icons--widgets:before {content: "widgets";}
.material-icons--testforge:before {-webkit-mask-image: url(../fonts/limitless/images/testforge.6b49bb7d2b4e24acd6131d88dd796bc3.svg);mask-image: url(../fonts/limitless/images/testforge.6b49bb7d2b4e24acd6131d88dd796bc3.svg);}
.material-icons--offline_bolt:before {content: "offline_bolt";}
.material-icons--code:before {content: "code";}
.material-icons--grain:before {content: "grain";}
.material-icons--settings_backup_restore:before {content: "settings_backup_restore";}
.material-icons--remove:before {content: "remove";}
.material-icons--person:before {content: "person";}
.material-icons--people:before {content: "people";}
.material-icons--blur_on:before {content: "blur_on";}
.material-icons--repeat:before {content: "repeat";}
.material-icons--settings:before {content: "settings";}
.material-icons--data_usage:before {content: "data_usage";}

.material-icons--custom:before {
    content: '';
    display: block;
    width: 21px;
    height: 21px;
    margin: 0 auto;
    -webkit-mask-position: center;
            mask-position: center;
    -webkit-mask-size: cover;
            mask-size: cover;
    -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.75);
}
