.fancytree-ext-filter-dimm span.fancytree-node span.fancytree-title {
    color: silver;
    font-weight: lighter;
}
.fancytree-ext-filter-dimm tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-submatch span.fancytree-title {
    color: black;
    font-weight: normal;
}
.fancytree-ext-filter-dimm tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-dimm span.fancytree-node.fancytree-match span.fancytree-title {
    color: black;
    font-weight: bold;
}
.fancytree-ext-filter-hide tr.fancytree-hide,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-hide {
    display: none;
}
.fancytree-ext-filter-hide tr.fancytree-submatch span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-submatch span.fancytree-title {
    color: silver;
    font-weight: lighter;
}
.fancytree-ext-filter-hide tr.fancytree-match span.fancytree-title,
.fancytree-ext-filter-hide span.fancytree-node.fancytree-match span.fancytree-title {
    color: black;
    font-weight: normal;
}
.fancytree-ext-childcounter span.fancytree-icon,
.fancytree-ext-filter span.fancytree-icon {
    position: relative;
}
.fancytree-ext-childcounter span.fancytree-childcounter,
.fancytree-ext-filter span.fancytree-childcounter {
    position: absolute;
    top: -6px;
    right: -6px;
    min-width: 10px;
    height: 10px;
    padding: 2px;
    text-align: center;
    vertical-align: baseline;
    color: #ffffff;
    border: 1px solid gray;
    border-radius: 10px;
    background: #777777;
    font-size: 9px;
    line-height: 1;
}
